import { render, staticRenderFns } from "./DirectMessage.vue?vue&type=template&id=2974ee2c&"
import script from "./DirectMessage.vue?vue&type=script&lang=js&"
export * from "./DirectMessage.vue?vue&type=script&lang=js&"
import style0 from "./DirectMessage.vue?vue&type=style&index=0&xscoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QDrawer,QItem,QItemSection,QScrollArea,QList,QInput,QIcon,QItemLabel,QBtn,QPageSticky,QPage,QCard,QCardSection,QLinearProgress,QColor,QField,Intersection} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QDrawer,QItem,QItemSection,QScrollArea,QList,QInput,QIcon,QItemLabel,QBtn,QPageSticky,QPage,QCard,QCardSection,QLinearProgress,QColor,QField})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {Intersection})
