//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SanghaAvatar from '@/components/SanghaAvatar.vue';
import DateComponent from '@/components/Together/DateComponent.vue';
import LightBlock from '@/components/Together/LightBlock.vue';
// components
import Post from '@/components/Together/Post.vue';
import PostCreate from '@/components/Together/PostCreate.vue';
import UserPickerModalComponent from '@/components/Together/UserPickerModal.vue';
import {
  detectPostTypes,
  getPostsFromResponse,
} from '@/components/Together/post.js';
import { Topics, Posts } from '@/services/api.service';
import {
  NUMBER_OF_MESSAGES_PER_PAGE,
  NUMBER_OF_TOPICS_TO_SUBSCRIBE,
  NUMBER_OF_TOPICS_PER_PAGE,
} from '@/services/config';
import { EventBus } from '@/services/event-bus';
import { WebSocket } from './WebSocket.js';

let vm = {
  name: 'DirectMessage',
  data() {
    return {
      drawer: true,
      showCreateConversationModal: false,
      topic: null,
      topicList: [],
      topicSearch: {
        searchString: null,
      },

      topicsWithNewMessages: [],
      topicsWithMyNewMessages: [],

      websocket: WebSocket(),

      posts: null,
      postMeta: {
        currentPage: 0,
        hasPrevPage: false,
        hasNextPage: false,
      },
      topicListMeta: {
        currentPage: 0,
        hasPrevPage: false,
        hasNextPage: false,
      },
      topicType: null,
      pagination: {
        page: null,
        limit: NUMBER_OF_MESSAGES_PER_PAGE,
        totalPages: 1,
      },
      inProgress: null,
      mutedChannels: [],
      topicSelected: false,

      windowWidth: window.innerWidth,
      cachedChannelMessages: {},
      cachedTopics: {},
      blocksForChannel: {},
      buttonIntersectionOptions: {
        handler: this.onLoadMoreButtonIntersection,
      },
      intersectionStarted: false,
      postToBeEdited: null,
    };
  },

  methods: {
    showUnfollowConfirm() {
      this.$q
        .dialog({
          title: this.$i18n.t('channels.leave_channel_dialog.title'),
          message: this.$i18n.t('channels.leave_channel_dialog.subtitle'),
          // ok: { label: this.$i18n.t('common.ok') },
          cancel: { flat: true, label: this.$i18n.t('common.cancel') },
          persistent: true,
        })
        .onOk(() => {
          this.inProgress++;
          Topics.unfollow(this.topic.channelId).then(
            (res) => {
              this.inProgress--;
              if (
                res &&
                res.data &&
                res.data.data &&
                res.data.data.unfollowTopic &&
                res.data.data.unfollowTopic.success
              ) {
                this.$q.notify({
                  message: this.$i18n.t(
                    'channels.leave_channel_dialog.after_dialog_message'
                  ),
                  //color: 'purple'
                });
              }
              this.getTopic({ force: true });
              this.loadTopicList({ page: 0 });
            },
            () => {
              this.inProgress--;
            }
          );
        })
        .onCancel(() => {})
        .onDismiss(() => {});
    },
    scrollIntoView(el) {
      const doc = document.querySelector('.replyTextarea');
      doc && doc.focus();
      el &&
        setTimeout(() => {
          document.getElementById(el).scrollIntoView({ behavior: 'auto' });
        }, 100);
    },

    gotoTopic(topic) {
      if (this.$route.params && this.$route.params.id === topic.channelId)
        return;
      this.postMeta.currentPage = 0;
      this.$router
        .push({ name: 'messages', params: { id: topic.channelId } })
        .catch(() => {});
    },

    searchQ() {
      this.loadTopicList({ indicator: true, page: 0 });
    },

    loadTopicList(options) {
      if (options && typeof options.page !== 'undefined')
        this.topicListMeta.currentPage = 0;
      let searchOptions = {
        limit: NUMBER_OF_TOPICS_PER_PAGE,
        offset: this.topicListMeta.currentPage * NUMBER_OF_TOPICS_PER_PAGE,
      };
      if (searchOptions.offset < 0) searchOptions.offset = 0;

      // console.log("so", searchOptions)
      this.inProgress++;

      // this.topicList = null;

      Topics.listConversations(searchOptions).then(
        (response) => {
          if (
            !response ||
            !response.data ||
            !response.data.data ||
            !response.data.data.getChats
          )
            return;
          let conversationsList = response.data.data.getChats;

          this.topicListMeta.hasNextPage = response.hasNextPage;
          this.topicListMeta.hasPrevPage = response.hasPrevPage;

          let topicCounter = 0;
          conversationsList.forEach((topic) => {
            topicCounter++;
            if (
              !topic.lastMessage.read &&
              this.topicsWithNewMessages.indexOf(topic.channelId) === -1
            )
              this.topicsWithNewMessages.push(topic.channelId);

            if (
              topicCounter < NUMBER_OF_TOPICS_TO_SUBSCRIBE &&
              options &&
              options.subscribeToAll
            ) {
              this.subscribeToWebsocket(topic.channelId, true);
            }
          });
          if (
            options &&
            options.gotoFirst === true &&
            conversationsList.length
          ) {
            // go to first topic listed
            this.$router
              .replace({
                name: 'messages',
                params: { id: conversationsList[0].channelId },
              })
              .catch(() => {});
          }
          if (options && options.append === true && conversationsList.length) {
            // append result to already listed items
            this.topicList = [...this.topicList, ...conversationsList];
          } else {
            this.topicList = conversationsList;
          }
          this.inProgress--;
        },
        () => {
          this.inProgress--;
        }
      );
    },

    loadNextPageOfChat() {
      this.postMeta.currentPage = this.postMeta.currentPage + 1;
      this.inProgress++;
      this.isLoadingNextPage = true;

      let options = {
        channelId: this.topic.channelId,
        limit: NUMBER_OF_MESSAGES_PER_PAGE,
        offset: NUMBER_OF_MESSAGES_PER_PAGE * this.postMeta.currentPage,
        imageWidth: 1024,
        imageHeight: 768,
      };

      Posts.listForWorkspace(options).then(
        (postsResponse) => {
          this.isLoadingNextPage = false;

          let newPosts = getPostsFromResponse(postsResponse, 'getMessages');
          if (newPosts === false) {
            this.inProgress--;
            return;
          }
          newPosts = detectPostTypes(newPosts);
          this.posts = [...newPosts, ...this.posts];

          if (newPosts.length) {
            const lastId =
              postsResponse.data.data.getMessages[
                postsResponse.data.data.getMessages.length - 1
              ].id;
            setTimeout(() => {
              document
                .getElementById('post_' + lastId)
                .scrollIntoView({ behavior: 'auto' });
            });
            setTimeout(() => {
              this.intersectionStarted = true;
            }, 500);
          }
          this.postMeta.hasNextPage = postsResponse.hasNextPage;
          this.postMeta.hasPrevPage = postsResponse.hasPrevPage;

          this.inProgress--;
        },
        () => {
          this.inProgress--;
          this.isLoadingNextPage = false;
        }
      );
    },

    loadNextPageOfTopics() {
      this.topicListMeta.currentPage = this.topicListMeta.currentPage + 1;
      this.loadTopicList({ append: true });
    },

    loadNextPageOfTopicsIntersection(obj) {
      if (!this.inProgress && obj.isIntersecting && obj.intersectionRatio > 0)
        this.loadNextPageOfTopics();
    },

    getTopic(params) {
      this.inProgress++;
      this.topicSelected = true;
      this.posts = [];

      let force = false;
      if (params && params.force) force = true;

      let processTopicFn = (response) => {
        this.topic = response.data.data.getChat;
        this.subscribeToWebsocket(this.$route.params.id);
        this.cachedTopics[this.$route.params.id] = response;
        if (!this.topic) {
          this.inProgress--;
          return;
        }
        this.listPosts(params);
      };
      if (
        this.cachedTopics &&
        this.cachedTopics[this.$route.params.id] &&
        !force
      ) {
        processTopicFn(this.cachedTopics[this.$route.params.id]);
      } else {
        Topics.getChat(this.$route.params.id).then(processTopicFn);
      }
    },

    readMessage(lastId, channelId) {
      Posts.read({ messageId: lastId, channelId: channelId }).then(() => {
        let id = this.topicsWithNewMessages.indexOf(channelId);
        if (id > -1) this.topicsWithNewMessages.splice(id, 1);
        this.topic.read = true;
      });
    },

    listPosts(params) {
      if (!this.topic.channelId) return;
      if (params && typeof params.page !== 'undefined')
        this.postMeta.currentPage = 0;

      let options = {
        channelId: this.topic.channelId,
        limit: NUMBER_OF_MESSAGES_PER_PAGE,
        offset: NUMBER_OF_MESSAGES_PER_PAGE * this.postMeta.currentPage,
        imageWidth: 800,
        imageHeight: 768,
      };

      let postResponseFn = (postsResponse) => {
        let newPosts = getPostsFromResponse(postsResponse, 'getChat');
        if (newPosts === false) {
          this.inProgress--;
          return;
        }

        this.intersectionStarted = false;
        this.cachedChannelMessages[this.topic.channelId] = postsResponse;

        this.posts = detectPostTypes(newPosts);

        this.postMeta.hasNextPage = postsResponse.hasNextPage;
        this.postMeta.hasPrevPage = postsResponse.hasPrevPage;

        this.inProgress--;

        if (this.posts.length) {
          const lastId =
            postsResponse.data.data.getChat[
              postsResponse.data.data.getChat.length - 1
            ].id;
          this.readMessage(lastId, this.topic.channelId);

          const isWithNewMessages = this.topicsWithNewMessages.indexOf(
            this.topic.channelId
          );
          if (isWithNewMessages > -1) {
            this.topicsWithNewMessages.splice(isWithNewMessages, 1);
          }
          const isWithMyNewMessages = this.topicsWithMyNewMessages.indexOf(
            this.topic.channelId
          );
          if (isWithMyNewMessages > -1) {
            this.topicsWithMyNewMessages.splice(isWithMyNewMessages, 1);
          }

          setTimeout(() => {
            let doc = document.getElementById('post_' + lastId);
            if (doc) doc.scrollIntoView({ behavior: 'auto' });

            doc = document.querySelector('.replyTextarea');
            if (doc) doc.focus();
          });
          setTimeout(() => {
            this.intersectionStarted = true;
          }, 500);
        }
      };

      Posts.listForUser(options).then(postResponseFn);
    },

    postSubmitFn(params) {
      this.blocksForChannel[this.topic.channelId] = null;
      this.loadTopicList({ page: 0 });
      if (params && params.reload) {
        setTimeout(() => {
          this.getTopic({ force: true, page: 0 });
        }, 100);
      }
    },

    joinChannel() {
      this.follow(this.topic, true);
    },

    subscribeToWebsocket(channelId, doNotSubscribeReactions) {
      this.websocket.subscribe(channelId);
      if (doNotSubscribeReactions === true) return;

      this.websocket.subscribeReactions(channelId);
    },

    /**
     * Socket receives data from onNewChatMessage GraphQl
     */
    onNewMessage(data) {
      if (data.post.author.id !== this.$store.state.profile.id) {
        if (this.mutedChannels.indexOf(data.channelId) === -1) {
          this.$refs.audio.play().then(
            () => {},
            () => {
              console.error('Not playable');
            }
          );
        }
        if (this.topicsWithNewMessages.indexOf(data.channelId) === -1)
          this.topicsWithNewMessages.push(data.channelId);
      } else {
        if (this.topicsWithMyNewMessages.indexOf(data.channelId) === -1)
          this.topicsWithMyNewMessages.push(data.channelId);
      }
      if (this.$route.params.id === data.channelId) {
        let post = data.post;
        post.isNewMessage = true;
        this.posts.push(post);
        this.posts = detectPostTypes(this.posts);
        this.scrollIntoView('post_' + post.id);
      }
    },

    onNewReaction(data) {
      if (!data || !data.channelId || !data.postReaction) return;
      if (this.$route.params.id === data.channelId) {
        let postWithReaction = this.posts.find(
          (i) => i.id === data.postReaction.postId
        );
        if (postWithReaction) {
          postWithReaction.reactions = data.postReaction.reactions;
        }
      }
    },

    saveBlockState(blocks) {
      if (
        this.topicsWithNewMessages.indexOf(this.topic.channelId) > -1 &&
        this.posts.length
      ) {
        const lastPost = this.posts[0];
        const lastId = lastPost.id;
        this.readMessage(lastId, this.topic.channelId);
      }

      if (!this.topic || !this.topic.channelId) return;
      this.blocksForChannel[this.topic.channelId] = blocks;
    },

    getSavedBlockState() {
      if (
        this.topic &&
        this.topic.channelId &&
        this.blocksForChannel[this.topic.channelId]
      ) {
        return this.blocksForChannel[this.topic.channelId];
      } else return null;
    },

    onLoadMoreButtonIntersection(obj) {
      if (!this.intersectionStarted) {
        return;
      }
      if (!this.inProgress && obj.isIntersecting && obj.intersectionRatio > 0) {
        this.intersectionStarted = false;
        this.loadNextPageOfChat();
      }
    },

    createConversationShowModal() {
      this.showCreateConversationModal = true;
    },

    createConversation(userProfile) {
      this.showCreateConversationModal = false;
      if (userProfile === null) return;
      const newTopic = {
        channelId: userProfile.channelId,
        userProfile,
        lastMessage: null,
      };
      if (
        this.topicList.filter((it) => it.channelId === userProfile.channelId)
          .length === 0
      ) {
        this.topicList = [...this.topicList, newTopic];
      }
      this.gotoTopic(newTopic);
    },

    editMessage(params) {
      this.postToBeEdited = params.post;
    },
  },
  watch: {
    '$route.params.id': function (newVal) {
      if (!newVal) return;
      this.intersectionStarted = false;
      this.getTopic();
    },
  },
  mounted() {
    this.inProgress = 0;
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth;
    });

    this.loadTopicList({ subscribeToAll: true, gotoFirst: true }); // (this.$route.params && this.$route.params.id ? false : true) });

    if (this.$route.params && this.$route.params.id) {
      this.getTopic();
    }

    EventBus.$on('onNewMessage', this.onNewMessage);
    EventBus.$on('onNewReaction', this.onNewReaction);
  },
  computed: {
    isUserBlocked() {
      if (!this.topic || !this.topic.user) return true;
      return this.topic.user.isBlocked;
    },
    userBlockType() {
      if (!this.topic || !this.topic.user) return null;
      return this.topic.user.blockState;
    },
    scrollHeightStyle() {
      if (this.topic) {
        return 'height: calc(100vh - 133px);';
      } else {
        return 'height: calc(100vh - 88px);';
      }
    },
    sidebarWidth() {
      return Math.max(300, Math.min(700, this.windowWidth / 3.2));
    },
    viewportWidth() {
      return this.windowWidth - this.sidebarWidth;
    },
  },
  beforeDestroy() {
    this.websocket.unsubscribeAll();
    this.websocket.unsubscribeReactions();
  },
  destroyed() {
    EventBus.$off('onNewMessage');
    EventBus.$off('onNewReaction');
  },
  components: {
    Post,
    PostCreate,
    DateComponent,
    UserPickerModalComponent,
    SanghaAvatar,
    LightBlock,
  },
};

export default vm;
